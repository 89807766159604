import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Context,
  GetOrCreateSessionAuthContext,
  isAuthenticatedWithValidToken,
  navigateTo,
  SecureCookieAttributes,
  Subscription,
  SubscriptionPlan,
} from "../../config/UserAuthContext";

// Components
import AccountInfo from "../../components/account/AccountInfo/AccountInfo";
import AccountSubscriptionInfo from "../../components/account/AccountSubscriptionInfo/AccountSubscriptionInfo";
import AccountUserDataPrivacy from "../../components/account/AccountUserDataPrivacy/AccountUserDataPrivacy";
import AccountCustomerSupport from "../../components/account/AccountCustomerSupport/AccountCustomerSupport";
import AccountDownloadApp from "../../components/account/AccountDownloadApp/AccountDownloadApp";
import AccountShare from "../../components/account/AccountShare/AccountShare";
import AccountFreeContent from "../../components/account/AccountFreeContent/AccountFreeContent";

import TagManager from "react-gtm-module";
import AccountServiceClient from "../../lib/ApiFunction";
import AccentStars from "../../global-assets/assets/Accent_Stars_02.svg";
import { PulseLoader } from "react-spinners";

/**
 * Account Management Page
 *
 * @param props
 * @constructor
 */
interface AccountSettingsProps {
  setAuth: any;
}

export const AccountSettings = ({ setAuth }: AccountSettingsProps) => {
  const [subscriptionData, setSubscriptionData] = useState<Subscription>();
  const { SubscriptionAPI } = AccountServiceClient();
  const osType = navigator.userAgent;
  const userContext = GetOrCreateSessionAuthContext();
  const subscriptionInfo = userContext.subscription.sources;
  const GlobalUserId = Cookies.get("GlobalUserId");

  const [loading, setLoading] = useState(true);

  const getSubscription = async () => {
    if (!isAuthenticatedWithValidToken()) {
      navigateTo("/login");
      setLoading(false);
      return;
    }

    const userContext = GetOrCreateSessionAuthContext();
    if (!userContext?.HasActiveSubscription) {
      await SubscriptionAPI.get("").then((res) => {
        setSubscriptionData(res.data);
        if (res.data.isActive) userContext.subscription = res.data;
      });
      Cookies.set(
        Context.UserContext,
        JSON.stringify(userContext),
        SecureCookieAttributes
      );
      setLoading(false);
    } else {
      setSubscriptionData(userContext?.subscription);
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubscription();

    TagManager.dataLayer({
      dataLayer: {
        event: "page_view",
        page_name: "accounts: dashboard",
        page_type: "accounts",
        os_type: osType,
        plan_type: subscriptionInfo[0]?.productId ?? "none",
        custom_user_id: GlobalUserId || undefined,
      },
    });
  }, []);

  if (loading) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-indigo-900">
        <PulseLoader color="#fff" />
      </div>
    );
  }

  return (
    <div
      id="test_data_settings_page"
      className="h-full min-h-[calc(100vh-272px)]"
    >
      <div className="tru-account-settings-bg absolute bottom-0 left-0 right-0 top-0 -z-[1] opacity-40" />
      <div className="relative overflow-hidden px-5 py-12 pt-24 sm:px-6 md:px-7 md:pt-32 lg:px-12 lg:py-36">
        <div className="mx-auto flex max-w-[1200px] flex-col gap-8">
          <div className="relative flex max-w-[226px] justify-start sm:max-w-[253px] md:max-w-[344px] lg:mx-auto lg:max-w-[434px] lg:justify-center">
            <h2 className="text-transparent bg-gradient_text bg-clip-text">
              Account Settings
            </h2>
            <img
              src={AccentStars}
              alt="Accent Stars"
              className="absolute -right-[55px] -top-5 w-[60px] md:-right-[70px] md:-top-[30px] md:w-[80px] lg:-right-[90px] lg:-top-[35px] lg:w-[100px]"
            />
          </div>
          <AccountInfo setAuth={setAuth} />
          <AccountFreeContent />
          {subscriptionData?.isActive && <AccountDownloadApp />}
          <AccountShare />
          <AccountSubscriptionInfo
            isActive={subscriptionData?.isActive as boolean}
            sources={subscriptionData?.sources as SubscriptionPlan[]}
            expireTime={subscriptionData?.expireTime as string}
          />
          <AccountUserDataPrivacy />
          <AccountCustomerSupport />
        </div>
      </div>
    </div>
  );
};
